import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import Picblock from '../../components/picblock';
import * as styles from '../../components/styles/index.module.css';

export default function SDRoiMonitoring({ data }) {
  const picdata = data.allMarkdownRemark.nodes;

  return (
    <Layout>
      <Seo title="ROI Monitoring Services" />
      <div className={styles.impact}>
        ROI Monitoring measures the impact of you data projects.
      </div>
      { picdata.map((data) => (
        <Picblock data={data} key={data.id} />
      ))}
    </Layout>
  );
}

export const query = graphql`
query roimonitoringpage {
    allMarkdownRemark(
      sort: {fields: frontmatter___sortorder, order: ASC}
      filter: {frontmatter: {page: {eq: "serviceroimonitoring"}}}
    ) {
      nodes {
        id
        frontmatter {
          page
          pictitle
          sortorder
          title
          imageside
          imageurl {
            childImageSharp {
              gatsbyImageData(layout: FIXED)
            }
          }
        }
        html
      }
    }
  }
  
`;

// TODO:  You can publish a test website with surge.sh.
